import DeleteIcon from "@mui/icons-material/Delete";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SaveIcon from "@mui/icons-material/Save";
import StraightenIcon from "@mui/icons-material/Straighten";
import { IconButton, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useMeasuringToolState } from "../../../../containers/MeasuringToolContainer";
import { Marking, MarkingType } from "../../../../types";

type Props = {
  activeMarking: Marking;
  setActiveMarking: (m: Marking) => void;
  onClickSave: () => void;
  onClickDelete: () => void;
};

export const RampCanvasToolbar: React.FC<Props> = ({
  activeMarking,
  setActiveMarking,
  onClickSave,
  onClickDelete,
}) => {
  const measuringToolState = useMeasuringToolState();
  const HAZARDS = [
    MarkingType.RED_SOLID,
    MarkingType.GREY,
    MarkingType.WHITE_SOLID,
    MarkingType.WHITE_DASHED,
    MarkingType.YELLOW_SOLID,
    MarkingType.YELLOW_DASHED,
    MarkingType.YELLOW_SOLID_CURVED,
  ];

  const activeTool = React.useMemo(() => {
    if (measuringToolState.active) {
      return "Measuring Tool";
    } else if (activeMarking?.type === MarkingType.OUTLINE) {
      return "Modify Outline";
    } else if (HAZARDS.indexOf(activeMarking?.type) > -1) {
      return "Add Hazard";
    } else {
      return "UNK";
    }
  }, [activeMarking?.type, measuringToolState.active]);

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mb: 1 }}>
      {activeMarking.geom.coordinates.flat().length > 0 ? (
        <>
          <Tooltip title="Save">
            <IconButton size="large" onClick={onClickSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="large" onClick={onClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          {1 === 2 && (
            <Tooltip title="Measuring Tool" placement="left">
              <IconButton
                size="large"
                onClick={() => {
                  setActiveMarking({
                    id: uuidv4(),
                    ramp_id: activeMarking.ramp_id,
                    type: MarkingType.RED_SOLID,
                    geom: {
                      type: "Polygon",
                      coordinates: [[]],
                    },
                  });
                  measuringToolState.setActive(!measuringToolState.active);
                }}
                sx={{
                  color:
                    activeTool === "Measuring Tool" ? "white" : "success.main",
                  backgroundColor:
                    activeTool === "Measuring Tool"
                      ? "success.main"
                      : "inherit",
                  "&:hover": {
                    color:
                      activeTool === "Measuring Tool"
                        ? "success.main"
                        : "success",
                    backgroundColor:
                      activeTool === "Measuring Tool" ? "grey" : "grey",
                  },
                }}
              >
                <StraightenIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Modify Outline">
            <IconButton
              size="large"
              onClick={() => {
                if (activeMarking?.type === MarkingType.OUTLINE) {
                  return;
                }
                measuringToolState.setActive(false);
                setActiveMarking({
                  id: uuidv4(),
                  ramp_id: activeMarking.ramp_id,
                  type: MarkingType.OUTLINE,
                  geom: {
                    type: "Polygon",
                    coordinates: [[]],
                  },
                });
              }}
              sx={{
                color:
                  activeTool === "Modify Outline" ? "white" : "success.main",
                backgroundColor:
                  activeTool === "Modify Outline" ? "success.main" : "inherit",
                "&:hover": {
                  color:
                    activeTool === "Modify Outline"
                      ? "success.main"
                      : "success",
                  backgroundColor:
                    activeTool === "Modify Outline" ? "grey" : "grey",
                },
              }}
            >
              <ImageAspectRatioIcon />
            </IconButton>
          </Tooltip>
          <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip title="Add Hazard">
              <IconButton
                size="large"
                onClick={() => {
                  measuringToolState.setActive(false);
                  if (HAZARDS.indexOf(activeMarking?.type) > -1) {
                    return;
                  }
                  setActiveMarking({
                    id: uuidv4(),
                    ramp_id: activeMarking.ramp_id,
                    type: MarkingType.RED_SOLID,
                    geom: {
                      type: "Polygon",
                      coordinates: [[]],
                    },
                  });
                }}
                sx={{
                  color: activeTool === "Add Hazard" ? "white" : "success.main",
                  backgroundColor:
                    activeTool === "Add Hazard" ? "success.main" : "inherit",
                  "&:hover": {
                    color:
                      activeTool === "Add Hazard" ? "success.main" : "success",
                    backgroundColor:
                      activeTool === "Add Hazard" ? "grey" : "grey",
                  },
                }}
              >
                <MyLocationIcon />
              </IconButton>
            </Tooltip>
            {HAZARDS.indexOf(activeMarking?.type) > -1 && (
              <TextField
                disabled={HAZARDS.indexOf(activeMarking?.type) === -1}
                size="small"
                select
                fullWidth
                label="Type"
                value={activeMarking?.type ?? MarkingType.RED_SOLID}
                onChange={(evt) => {
                  setActiveMarking({
                    id: uuidv4(),
                    ramp_id: activeMarking.ramp_id,
                    type: evt.target.value as MarkingType,
                    geom: {
                      type: "Polygon",
                      coordinates: [[]],
                    },
                  });
                }}
                sx={{ minWidth: 175 }}
              >
                <MenuItem value={MarkingType.RED_SOLID}>Red Solid</MenuItem>
                <MenuItem value={MarkingType.GREY}>Grey</MenuItem>
                <MenuItem value={MarkingType.WHITE_SOLID}>White Solid</MenuItem>
                <MenuItem value={MarkingType.WHITE_DASHED}>
                  White Dashed
                </MenuItem>
                <MenuItem value={MarkingType.YELLOW_SOLID}>
                  Yellow Solid
                </MenuItem>
                {/* <MenuItem value={MarkingType.YELLOW_SOLID_CURVED}>
            Yellow Solid Curved
          </MenuItem> */}
                <MenuItem value={MarkingType.YELLOW_DASHED}>
                  Yellow Dashed
                </MenuItem>
              </TextField>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
