import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Position } from "geojson";
import { round } from "lodash";
import * as React from "react";
import { Marking, MarkingType, Ramp } from "../../../../types";
import { uuidv4 } from "../../../../utils";
import Canvas from "../../../../widgets/useCanvas";
import { ImageDimensions } from "./RampIniitalize";

type Props = {
  imageDimensions: ImageDimensions;
  ramp: Ramp;
  setRamp: (ramp: Ramp) => void;
  showReferenceImage: boolean;
};

export type CanvasStyleOptions = {
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: string;
};

export const traversePoints = (
  ctx: CanvasRenderingContext2D,
  points: Position[],
  style: any,
  feetToPixels: (ft: number) => number,
  closePolygon: boolean
) => {
  let isFirst = true;
  for (const point of points) {
    const pointInPixels = point.map(feetToPixels);
    ctx.fillRect(
      pointInPixels[0] - style.lineWidth,
      ctx.canvas.height - pointInPixels[1] - style.lineWidth,
      style.lineWidth * 2,
      style.lineWidth * 2
    );
    if (isFirst) {
      ctx.moveTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
      isFirst = false;
    } else {
      ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
    }
  }
  if (closePolygon && points?.length) {
    const pointInPixels = points[0].map(feetToPixels);
    ctx.lineTo(pointInPixels[0], ctx.canvas.height - pointInPixels[1]);
  }
};

export const drawMarking = (
  ctx: CanvasRenderingContext2D,
  marking: Marking,
  feetToPixels: (ft: number) => number
) => {
  ctx.beginPath();
  ctx.save();

  const style = {
    fillStyle: "magenta",
    strokeStyle: "magenta",
    lineWidth: 2,
    lineCap: null,
  };
  if (marking.type === MarkingType.OUTLINE) {
    style.fillStyle = "#3c6ce9";
    style.strokeStyle = "#3c6ce9";
    style.lineWidth = 4;
  } else if (marking.type === MarkingType.RED_SOLID) {
    style.strokeStyle = "red";
    style.fillStyle = "red";
    style.lineWidth = 4;
  } else if (marking.type === MarkingType.GREY) {
    style.strokeStyle = "grey";
    style.fillStyle = "grey";
    style.lineWidth = 4;
  } else if (marking.type === MarkingType.WHITE_SOLID) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = 4;
  } else if (marking.type === MarkingType.WHITE_DASHED) {
    style.strokeStyle = "#FCFAFF";
    style.fillStyle = "#FCFAFF";
    style.lineWidth = 4;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.YELLOW_SOLID) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = 4;
  } else if (marking.type === MarkingType.YELLOW_SOLID_CURVED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = 4;
    style.lineCap = "round";
  } else if (marking.type === MarkingType.YELLOW_DASHED) {
    style.strokeStyle = "#eed202";
    style.fillStyle = "#eed202";
    style.lineWidth = 4;
    ctx.setLineDash([10]);
  } else if (marking.type === MarkingType.REFERENCE_DISTANCE) {
    style.strokeStyle = "#e3c783";
    style.fillStyle = "#e3c783";
    style.lineWidth = 4;
  } else {
    ctx.restore();
    return;
  }

  ctx.fillStyle = style.fillStyle;
  ctx.strokeStyle = style.strokeStyle;
  ctx.lineWidth = style.lineWidth;

  const points = marking?.geom.coordinates.flat() ?? [];

  traversePoints(
    ctx,
    points,
    style,
    feetToPixels,
    marking.type === MarkingType.OUTLINE
  );
  ctx.stroke();

  if (marking.type === MarkingType.OUTLINE) {
    ctx.beginPath();
    traversePoints(ctx, points, style, feetToPixels, true);
    ctx.fillStyle = "#D3D3D360";
    ctx.fill();
  }
  ctx.restore();
};

export const RampCalibrationCanvas: React.FC<Props> = ({
  imageDimensions,
  ramp,
  setRamp,
}) => {
  const [
    showReferenceDistanceDialog,
    setShowReferenceDistanceDialog,
  ] = React.useState<boolean>(false);
  const [distanceFeet, setDistanceFeet] = React.useState<number>();
  const [activeMarking, setActiveMarking] = React.useState<Marking>({
    id: uuidv4(),
    ramp_id: ramp.id,
    type: MarkingType.REFERENCE_DISTANCE,
    geom: {
      type: "Polygon",
      coordinates: [[]],
    },
  });

  React.useEffect(() => {
    if (activeMarking?.geom.coordinates[0].length === 2) {
      setShowReferenceDistanceDialog(true);
    }
  }, [activeMarking]);

  const canvasRef = React.createRef();
  // Ensure the image dimensions have a max height of 600px and a max width of 1000px
  const adjustedImageDimensions = React.useMemo(() => {
    const maxHeight = 600;
    const maxWidth = 1000;
    let { width, height } = imageDimensions;

    if (height > maxHeight) {
      const ratio = maxHeight / height;
      height = maxHeight;
      width = width * ratio;
    }

    if (width > maxWidth) {
      const ratio = maxWidth / width;
      width = maxWidth;
      height = height * ratio;
    }

    return { width, height };
  }, [imageDimensions]);

  const canvasHeight = adjustedImageDimensions.height;
  const canvasWidth = adjustedImageDimensions.width;

  const draw = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      // draw stuff!
      // the active thing being marked up
      drawMarking(ctx, activeMarking, (x) => x);
    },
    [ramp, activeMarking, canvasHeight, canvasWidth]
  );

  const handleMouseDown = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    const x = Math.round(evt.clientX - rect.left);
    const y = canvasHeight - Math.round(evt.clientY - rect.top);

    if (!activeMarking?.type) {
      return;
    }

    if (
      activeMarking.type === MarkingType.REFERENCE_DISTANCE &&
      activeMarking.geom.coordinates[0].length > 1
    ) {
      return;
    }

    setActiveMarking({
      ...activeMarking,
      geom: {
        ...activeMarking.geom,
        coordinates: [activeMarking?.geom.coordinates[0].concat([[x, y]])],
      },
    });
  };

  const handleMouseMove = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    //
  };

  const handleMouseExit = (evt) => {
    const canvas = evt.target;
    const rect = canvas.getBoundingClientRect();
    //
  };

  const cursor = React.useMemo(() => {
    return "crosshair";
  }, []);

  if (!imageDimensions?.width) {
    return null;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Box
        sx={{
          cursor,
          position: "relative",
        }}
      >
        <Stack direction="row" spacing={1}>
          {ramp?.depth && (
            <Stack
              direction="column"
              alignItems="center"
              sx={{ height: canvasHeight }}
            >
              <div
                style={{
                  width: 5,
                  height: "100%",
                  backgroundColor: "lightgrey",
                }}
              />
              <Typography
                align="center"
                variant="caption"
                sx={{ pt: 1, pb: 1 }}
              >
                {ramp?.depth && round(ramp?.depth, 0)} ft
              </Typography>
              <div
                style={{
                  width: 5,
                  height: "100%",
                  backgroundColor: "lightgrey",
                }}
              />
            </Stack>
          )}
          <Stack direction="column" spacing={1}>
            <Canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              draw={draw}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseExit}
              onMouseLeave={handleMouseExit}
              style={{
                backgroundImage: `url(${ramp.reference_image})`,
                backgroundSize: "cover",
              }}
            />
            {ramp?.width && (
              <Stack direction="row" alignItems="center">
                <div
                  style={{
                    width: "100%",
                    height: 5,
                    backgroundColor: "lightgrey",
                  }}
                />
                <Typography
                  align="center"
                  variant="caption"
                  component="div"
                  sx={{ minWidth: 100, pl: 1, pr: 1 }}
                >
                  {ramp?.width && round(ramp?.width, 0)} ft
                </Typography>
                <div
                  style={{
                    width: "100%",
                    height: 5,
                    backgroundColor: "lightgrey",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
      <Dialog
        open={showReferenceDistanceDialog}
        onClose={() => setShowReferenceDistanceDialog(false)}
      >
        <DialogTitle>Reference Distance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            How long is the reference segment?
          </DialogContentText>
          <TextField
            autoFocus
            type="number"
            size="small"
            fullWidth
            margin="dense"
            label="Distance (ft)"
            variant="outlined"
            onChange={(evt) => setDistanceFeet(parseFloat(evt.target.value))}
            value={distanceFeet}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowReferenceDistanceDialog(false);
              setActiveMarking({
                id: uuidv4(),
                type: null,
                geom: {
                  type: "Polygon",
                  coordinates: [[]],
                },
              });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setShowReferenceDistanceDialog(false);
              const p1 = activeMarking.geom.coordinates[0][0];
              const p2 = activeMarking.geom.coordinates[0][1];
              const distancePixels = Math.sqrt(
                (p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2
              );
              const currentImageFeetPerPixel = distanceFeet / distancePixels;
              const imageWidthInFeet = currentImageFeetPerPixel * canvasWidth;
              setRamp({
                ...ramp,
                width: round(imageWidthInFeet, 2),
                depth: round(
                  imageWidthInFeet *
                    (imageDimensions.height / imageDimensions.width),
                  2
                ),
              });
              setActiveMarking({
                id: uuidv4(),
                type: null,
                geom: {
                  type: "Polygon",
                  coordinates: [[]],
                },
              });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
